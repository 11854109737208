import JwtDecode from "jwt-decode";
import axios from "axios";
import evacmanapi from "../../evacmanapi";

const auth = {
  namespaced: true,
  state: () => ({
    user: null,
    token: localStorage.getItem("token") || "",
    status: "",
  }),
  actions: {
    AUTH_REQUEST: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        // The Promise used for router redirect in login
        commit("AUTH_REQUEST");
        evacmanapi({ requiresAuth: false })({
          url: "/login",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem("token", token); // store the token in localstorage
            commit("AUTH_SUCCESS", token);
            // you have your token, now log in your user :)
            commit("UserFromLocalStorage");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR", err);
            localStorage.removeItem("token"); // if the request fails, remove any possible user token if possible
            reject(err.response);
          });
      });
    },
    AUTH_LOGOUT: ({ commit }) => {
      return new Promise((resolve) => {
        commit("AUTH_LOGOUT");
        localStorage.removeItem("token"); // clear your user's token from localstorage
        resolve();
      });
    },
    login({ commit }) {
      commit("Authenticate");
    },
    logout({ commit }) {
      commit("Logout");
    },
    getToken({ commit }, { user, pass }) {
      return new Promise((resolve, reject) => {
        axios
          .post("http://localhost:40000/api/v1/login", {
            username: user,
            password: pass,
          })
          .then((response) => {
            commit("Authenticate", response.data.token);
            resolve(commit("Authenticate", response.data.token));
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    setUserFromLocalStorage({ commit }) {
      if (localStorage.getItem("token")) {
        commit("UserFromLocalStorage");
      }
    },
  },
  mutations: {
    AUTH_REQUEST: (state) => {
      state.status = "loading";
    },
    AUTH_SUCCESS: (state, token) => {
      state.status = "success";
      state.token = token;
    },
    AUTH_ERROR: (state) => {
      state.status = "error";
    },
    Authenticate(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
      state.user = JwtDecode(token);
    },
    Logout(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
    },
    UserFromLocalStorage(state) {
      state.token = localStorage.getItem("token");
      state.user = JwtDecode(state.token);
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    currentUser(state) {
      return state.user;
    },
    currentToken(state) {
      return state.token;
    },
  },
};

export default auth;
