import axios from "axios";

export default ({ requiresAuth = false } = {}) => {
  const options = {};
  options.baseURL = process.env.VUE_APP_API_URL;

  // Falls Authentisierung erforderlich ist, Token mitsenden
  if (requiresAuth) {
    options.headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
  }
  const instance = axios.create(options);
  return instance;
};
