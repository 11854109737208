import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/components/Login"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/components/Logout"),
  },
  {
    path: "/evacuation/live",
    name: "LiveEvacuation",
    component: () => import("@/components/LiveEvacuation"),
  },
  {
    path: "/evacuation/:evacuationID",
    name: "Evacuation",
    component: () => import("@/components/Evacuation"),
  },
  {
    path: "/administration/evacuations",
    name: "administration/evacuations",
    component: () => import("@/components/administration/Evacuations"),
  },
  {
    path: "/administration/users",
    name: "administration/users",
    component: () => import("@/components/administration/Users"),
  },
  {
    path: "/global-administration",
    name: "global-administration",
    component: () => import("@/components/global-administration/Dashboard"),
  },
  {
    path: "/global-administration/companies",
    name: "global-administration/companies",
    component: () => import("@/components/global-administration/Companies"),
  },
  {
    path: "/global-administration/users",
    name: "global-administration/users",
    component: () => import("@/components/global-administration/Users"),
  },
  {
    path: "/global-administration/roles",
    name: "global-administration/roles",
    component: () => import("@/components/global-administration/Roles"),
  },
  {
    path: "/global-administration/apitoken",
    name: "global-administration/apitoken",
    component: () => import("@/components/global-administration/APIToken"),
  },
  {
    path: "/global-administration/evacuations",
    name: "global-administration/evacuations",
    component: () => import("@/components/global-administration/Evacuations"),
  },
  {
    path: "/global-administration/sessions",
    name: "global-administration/sessions",
    component: () => import("@/components/global-administration/Sessions"),
  },
];

const router = new VueRouter({
  //  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
