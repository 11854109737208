import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import users from "./modules/users";
import evacuees from "./modules/evacuees";
import companies from "./modules/companies";
import roles from "./modules/roles";
import apitokens from "./modules/apitokens";
import sessions from "./modules/sessions";
import evacuations from "./modules/evacuations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    users: users,
    evacuees: evacuees,
    companies: companies,
    roles: roles,
    apitokens: apitokens,
    sessions: sessions,
    evacuations: evacuations,
  },
});
