import evacmanapi from "../../evacmanapi";

const apiendpoint = "/role";

const roles = {
  namespaced: true,
  state: () => ({
    items: [],
    loading: true,
  }),

  actions: {
    updateData({ commit }) {
      return new Promise((resolve, reject) => {
        evacmanapi({ requiresAuth: true })({ url: apiendpoint, method: "GET" })
          .then((response) => {
            resolve(
              commit("updateData", response.data),
              commit("changeLoadingState", false)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("payload ", { payload });
        evacmanapi({ requiresAuth: true })({
          url: apiendpoint,
          data: payload,
          method: "POST",
        })
          .then((response) => {
            resolve(
              commit("add", response.data),
              console.log("commitdata add:", response.data)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("payload ", { payload });
        evacmanapi({ requiresAuth: true })({
          url: apiendpoint,
          data: payload,
          method: "PUT",
        })
          .then((response) => {
            resolve(
              // commit('add', response.data),
              console.log("commitdata update:", response.data)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("payload ", { payload });
        evacmanapi({ requiresAuth: true })({
          url: apiendpoint,
          data: payload,
          method: "DELETE",
        })
          .then((response) => {
            resolve(console.log("commitdata delete:", response.data));
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
  },
  getters: {
    items(state) {
      return state.items;
    },
    loadingState(state) {
      return state.loading;
    },
  },

  mutations: {
    add(state, item) {
      state.items.push(item);
    },
    delete(state, item) {
      state.items.push(item);
    },
    updateData(state, items) {
      state.items = items;
    },
    changeLoadingState(state, loading) {
      state.loading = loading;
    },
  },
};

export default roles;
