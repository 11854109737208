<template>
  <v-app id="evacmanager">
    <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      width="auto"
      app
      clipped
    >
      <v-list dense>
        <router-link v-if="currentUser" tag="span" to="/evacuation/live">
          <v-list-item link @click="drawer = !drawer">
            <v-list-item-action>
              <v-icon>mdi-alarm-light</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Evakuierungsansicht</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-group
          v-if="currentUser.role == 'Administrator'"
          :value="false"
          prepend-icon="mdi-hammer-wrench"
        >
          <template #activator>
            <v-list-item-title>Administration</v-list-item-title>
          </template>
          <router-link
            v-if="currentUser.role == 'Administrator'"
            tag="span"
            :to="{ name: 'administration/evacuations' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-run-fast</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Evakuierungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Administrator'"
            tag="span"
            :to="{ name: 'administration/users' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Benutzer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
        <v-list-group
          v-if="currentUser.role == 'Globaler Administrator'"
          :value="false"
          prepend-icon="mdi-hammer-wrench"
        >
          <template #activator>
            <v-list-item-title>Globale Administration</v-list-item-title>
          </template>

          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            to="/global-administration"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/companies' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Firmen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/users' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Benutzer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/roles' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/evacuations' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-run-fast</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Evakuierungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/sessions' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-account-clock</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Sitzungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="currentUser.role == 'Globaler Administrator'"
            tag="span"
            :to="{ name: 'global-administration/apitoken' }"
          >
            <v-list-item link @click="drawer = !drawer">
              <v-list-item-action>
                <v-icon>mdi-key</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>API-Tokens</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="currentUser" app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-img
          contain
          height="4em"
          lazy-src="./assets/evacmanager_logo.png"
          src="./assets/evacmanager_logo.png"
        ></v-img
      ></v-app-bar-nav-icon>

      <v-toolbar-title v-if="$vuetify.breakpoint.lgAndUp" class="text-h6">{{
        title
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row>
        <template v-if="currentUser.company === 'KRACHT GmbH'">
          <v-col
            ><v-container
              ><v-img
                class="mx-auto"
                contain
                height="3em"
                max-height="3em"
                lazy-src="./assets/krachtlogo.gif"
                src="./assets/krachtlogo.gif"
              ></v-img></v-container
          ></v-col>
        </template>
        <template v-if="currentUser.company === 'Paul Müller GmbH'">
          <v-col
            ><v-container
              ><v-img
                class="mx-auto"
                contain
                height="3em"
                max-height="3em"
                lazy-src="./assets/pmlogo.png"
                src="./assets/pmlogo.png"
              ></v-img></v-container
          ></v-col>
        </template>
        <v-col v-if="currentUser.company !== 'Paul Müller GmbH' && currentUser.company !== 'KRACHT GmbH'" class="text-h5" justify="center" align="center">{{
          currentUser.company
        }}</v-col
        ><v-spacer></v-spacer>
        <v-col justify="end" align="end">
          {{ currentUser.name }} | {{ currentUser.role }}
          <router-link tag="span" to="/logout">
            <v-btn small color="primary" dark class="text-body-1">Logout</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer v-if="currentUser && $vuetify.breakpoint.lgAndUp" app>
      <v-row no-gutters dense>
        <v-col class="text-left">
          v{{ version
          }}<span v-if="env === 'Dev'">-dev (Build {{ buildtime }})</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right"
          >&copy;{{ new Date().getFullYear() }} WKN Datentechnik GmbH</v-col
        >
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: false,
    title: process.env.VUE_APP_TITLE,
    version: process.env.VUE_APP_VERSION,
    env: process.env.VUE_APP_ENV,
    buildtime: process.env.VUE_APP_BUILDTIME,
  }),
  computed: {
    ...mapGetters("auth", {
      currentUser: "currentUser",
      currentToken: "currentToken",
    }),
  },
  created() {
    this.$vuetify.theme.dark = true;
    document.title = process.env.VUE_APP_TITLE;

    if (!this.currentUser || !this.currentToken) {
      this.checkLogin();
    }
  },

  updated() {
    if (!this.currentUser || !this.currentToken) {
      this.checkLogin();
    }
  },
  methods: {
    checkLogin() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("auth/logout");
        if (this.$route.path !== "/") {
          this.$router.replace(this.$route.query.redirect || "/");
        }
      }
      this.$store.dispatch("auth/setUserFromLocalStorage");
    },
  },
};
</script>

<style></style>
