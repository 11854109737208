import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.filter("formatTimeString", function (value) {
  if (!value || value === null) return "";
  if (typeof value === "object") {
    if ("Valid" in value && "Time" in value) {
      if (!value.Valid) return "";
      value = value.Time;
    }
  }

  const date = new Date(value);
  const timeString = date.toLocaleString("de-DE");
  return timeString;
});

new Vue({
  router,
  store,
  template: "<App/>",
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
