import evacmanapi from "../../evacmanapi";

const evacuees = {
  namespaced: true,
  state: () => ({
    evacuees: [],
    loading: true,
    submitstatus: "",
    error: false,
    errorMessage: "",
    showEvacuated: false,
    statistics: {},
    latestactiveevacuationid: null,
  }),

  actions: {
    setErrorState({ commit }, { errorstate }) {
      if (errorstate === true) {
        commit("ERROR_TRUE");
      } else {
        commit("ERROR_FALSE");
      }
    },
    updateData({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("changeLoadingState", true);
        let endpointurl = "";
        if (state.showEvacuated) {
          endpointurl = "/evacuees/all";
        } else {
          endpointurl = "/evacuees";
        }
        evacmanapi({ requiresAuth: true })({ url: endpointurl, method: "GET" })
          .then((response) => {
            resolve(
              commit("updateEvacuees", response.data),
              commit("changeLoadingState", false)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    updateDataByID({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("payload ", { payload });
        commit("changeLoadingState", true);
        const endpointurl = "/evacuation/" + payload.ID;
        evacmanapi({ requiresAuth: true })({ url: endpointurl, method: "GET" })
          .then((response) => {
            resolve(
              commit("updateEvacuees", response.data.Evacuees),
              commit("changeLoadingState", false)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    updateStatistics({ commit }) {
      return new Promise((resolve, reject) => {
        commit("changeLoadingState", true);
        evacmanapi({ requiresAuth: true })({
          url: "/evacuees/stats",
          method: "GET",
        })
          .then((response) => {
            resolve(
              commit("updateStatistics", response.data),
              commit("changeLoadingState", false)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    updateLatestActiveEvacuationID({ commit }) {
      return new Promise((resolve, reject) => {
        commit("changeLoadingState", true);
        evacmanapi({ requiresAuth: true })({
          url: "/evacuees/latestactiveevacuationid",
          method: "GET",
        })
          .then((response) => {
            resolve(
              commit(
                "updateLatestActiveEvacuationID",
                response.data.latestactiveevacuationid
              ),
              commit("changeLoadingState", false)
            );
          })
          .catch((error) => {
            reject(error.response.data.message);
            this.dispatch("auth/logout", null, { root: true });
          });
      });
    },
    evacuateEvacuee(
      { commit },
      {
        evacuationtype,
        evacuationid,
        evacueeid,
        evacueeident,
        submittinguserid,
        submittingusername,
      }
    ) {
      return new Promise((resolve, reject) => {
        commit("SUBMITTING_REQUEST");
        evacmanapi({ requiresAuth: true })({
          url: "/evacuees/evacuate",
          data: {
            evacuationtype,
            evacuationid,
            evacueeid,
            evacueeident,
            submittinguserid,
            submittingusername,
          },
          method: "PUT",
        })
          .then((response) => {
            resolve(commit("SUBMITTING_SUCCESS"));
            commit("ERROR_FALSE");
          })
          .catch((error) => {
            commit("SUBMITTING_ERROR", error);
            commit("ERROR_TRUE");
            reject(error.response);
          });
      });
    },
    enableShowEvacuated({ commit }) {
      commit("SHOWEVACUATED_ENABLE");
    },
    disableShowEvacuated({ commit }) {
      commit("SHOWEVACUATED_DISABLE");
    },
  },
  getters: {
    evacuees(state) {
      return state.evacuees;
    },
    statistics(state) {
      return state.statistics;
    },
    error(state) {
      return state.error;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    latestactiveevacuationid(state) {
      return state.latestactiveevacuationid;
    },
    loadingState(state) {
      return state.loading;
    },
    submitStatus: (state) => state.status,
    showEvacuated(state) {
      return state.showEvacuated;
    },
  },

  mutations: {
    SUBMITTING_REQUEST: (state) => {
      state.submitstatus = "loading";
    },
    SUBMITTING_SUCCESS: (state) => {
      state.submitstatus = "success";
    },
    SUBMITTING_ERROR: (state) => {
      state.submitstatus = "error";
    },
    updateEvacuees(state, newevacuees) {
      state.evacuees = newevacuees;
    },
    updateStatistics(state, statistics) {
      state.statistics = statistics;
    },
    updateLatestActiveEvacuationID(state, latestactiveevacuationid) {
      state.latestactiveevacuationid = latestactiveevacuationid;
    },
    changeLoadingState(state, loading) {
      state.loading = loading;
    },
    changeSubmittingState(state, submitting) {
      state.submitting = submitting;
    },
    SHOWEVACUATED_ENABLE: (state) => {
      state.showEvacuated = true;
    },
    SHOWEVACUATED_DISABLE: (state) => {
      state.showEvacuated = false;
    },
    ERROR_TRUE: (state) => {
      state.error = true;
    },
    ERROR_FALSE: (state) => {
      state.error = false;
    },
  },
};

export default evacuees;
